.sideBarLink {
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
}

.sideBarLink:hover {
  background-color: var(--background_Color);
  padding-left: 1.5rem;
}

.react-modal-sheet-container {
  background-color: #F5F6FB !important;
  border-top-right-radius: 24px !important;
  border-top-left-radius: 24px !important
}

.dark-sheet .react-modal-sheet-container {
  background-color: #161616 !important;
}

.react-modal-sheet-backdrop {
  background-color: transparent !important;
}

.light-sheet,
.dark-sheet {
  z-index: 999999999 !important;
}

.sheet-post .react-modal-sheet-container {
  border-top-right-radius: 40px !important;
  border-top-left-radius: 40px !important;
}

.sheet-post .react-modal-sheet-header {
  height: 0 !important;
  opacity: 0;
}

.react-modal-sheet-content .tox .tox-edit-area__iframe {
  background-color: transparent !important;
}

@media (min-width:1024px) {
  .homeGeneral {
    background-image: url('/background.svg');
    background-repeat: no-repeat;
    background-position: -11px -57px;
    /* transition: all 2s ease; */
  }
}

@media (max-width:1024px) {
  .transak-modal{
    padding-inline: 20px;
    height: calc(100% - 248px) !important;
  }
  #transakCloseIcon{
    right: 20px !important;
  }
}